<template>
  <div class="home">
    <!-- <Header /> -->
    <!-- 搜索 -->
    <!-- 头部 -->
    <div class="search">
      <div class="search_input">
        <el-input v-model="search.condition" placeholder="查找姓名"></el-input>
      </div>
      <div class="search_input">
        <el-input v-model="search.companyName" placeholder="公司名称"></el-input>
      </div>
      <div class="search_input">
        <el-select v-model="search.gender" placeholder="性别">
          <el-option label="全部" :value="null"> </el-option>
          <el-option label="男" :value="0"> </el-option>
          <el-option label="女" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="search_input">
        <el-input type="number" v-model.number="search.startIntegral" @change="handleInput"
          placeholder="起始积分"></el-input>
      </div>
      <div class="search_input">
        <el-input type="number" v-model.number="search.endIntegral" @change="handleInput1"
          placeholder="截止积分"></el-input>
      </div>
      <div class="search_input">
        <el-button class="btn" type="primary" @click="reset">重置</el-button>
        <el-button class="btn" type="primary" @click="getSearch">搜索</el-button>
        <el-button class="btn" icon="el-icon-download" type="primary" @click="handleDownload">下载</el-button>
      </div>
      <div class="grid-content search_input header_list">
        <span>已注册用户统计</span>
        <h2>{{ num }}</h2>
      </div>
    </div>
    <MoTable :cols="cols" :tableData="tableData" @handleEventButton="handleEventButton" :operation="operation"
      :page="page" />
    <!-- 详情弹窗 -->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="姓名">
          <el-input disabled v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input disabled v-model="form.age"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select disabled v-model="form.gender" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分">
          <el-input disabled v-model="form.integral"></el-input>
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input disabled v-model="form.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input disabled v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-input disabled v-model="form.createTime"></el-input>
        </el-form-item>
        <!-- <el-form-item label="身份证">
          <el-input disabled v-model="form.createTime"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="身份证">
          <img src="../../assets/bg.png" alt="">
          <img src="form.frontUrl" alt="">
        </el-form-item> -->
        <el-row>
          <el-col>
            <el-form-item label="身份证">
              <div class="img">
                <el-image style="width: 100px; height: 100px; margin-right: 10px" :src="form.frontUrl"
                  :preview-src-list="preview">
                </el-image>
                <el-image style="width: 100px; height: 100px" :src="form.backUrl">
                </el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改积分" :visible.sync="integralVisible" :before-close="handleClose" width="20%">
      <el-form label-width="80px" ref="form" :model="form" :rules="rulesForm">
        <el-form-item label="加减选择" prop="type">
          <el-select v-model="form.type" placeholder="请选择加减">
            <el-option v-for="item in optionsType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分" prop="integral">
          <el-input placeholder="请选择积分" v-model.number="form.integral"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onIntergral('form')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改总积分 -->
    <el-dialog title="修改总积分" :visible.sync="inteNumVisible" :before-close="handleCloses" width="20%">
      <el-form label-width="80px" ref="forms" :model="forms" :rules="rulesForms">
        <el-form-item label="加减选择" prop="type">
          <el-select v-model="forms.type" placeholder="请选择加减">
            <el-option v-for="item in optionsType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="总积分" prop="integral">
          <el-input placeholder="请选择积分" v-model.number="forms.integral"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloses">取 消</el-button>
        <el-button type="primary" @click="onIntergralNum('forms')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="我的邀请" :visible.sync="inviteVisible" :before-close="handleInviteCloses" width="40%">
      <el-table :data="inviteData" stripe style="width: 100%">
        <el-table-column type="index" label="序号" align="center"> </el-table-column>
        <el-table-column prop="name" label="姓名" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="时间" align="center"> </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next,total" @current-change="handelInviteChange" :total="pageInvite.total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleInviteCloses">取 消</el-button>
        <el-button type="primary" @click="handleInviteCloses">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
let political = [{
  id: '0',
  name: '群众',
},
{
  id: '1',
  name: '共青团员',
},
{
  id: '2',
  name: '党员',
}]
let cascader = [
  {
    id: '0',
    name: '莲花社区',
  },
  {
    id: '1',
    name: '华成路社区',
  },
  {
    id: '2',
    name: '龙舟社区',
  },
  {
    id: '3',
    name: '九眼桥社区',
  },
  {
    id: '4',
    name: '得胜街社区',
  },
  {
    id: '5',
    name: '水碾河路南社区',
  },
  {
    id: '6',
    name: '莲桂西路社区',
  },
  {
    id: '7',
    name: '紫东社区',
  },
  {
    id: '8',
    name: '锦东社区',
  },
  {
    id: '9',
    name: '一心桥社区',
  },
]
export default {
  name: "User",
  components: {
    MoTable,
  },
  data() {
    return {
      search: {
        condition: "",
        companyName: "",
        gender: null,
        startIntegral: null,
        endIntegral: null,
      },
      inviteData: [],
      url: "",
      cols: [
        { type: "index", label: "序号" },
        { width: "150", label: "姓名", prop: "name" },
        { width: "150", label: "年龄", prop: "age" },
        {
          width: "150",
          label: "性别",
          prop: "gender",
          formatter: (row, column, cellValue, index) => {
            if (row.gender == 0) {
              return "男";
            } else if (row.gender == 1) {
              return "女";
            } else {
              return "";
            }
          },
        },
        { width: "150", label: "积分", prop: "integral" },
        { width: "150", label: "总积分", prop: "cumulativeIntegral" },
        { label: "地址", prop: "address" },
        {
          label: "公司",
          prop: "companyName",
        },
        {
          label: "政治面貌",
          prop: "politicalAffiliation",
          formatter: (row, column, cellValue, index) => {
            if (row.politicalAffiliation == 0 || row.politicalAffiliation) {
              return political[row.politicalAffiliation].name
            }
          },
        },
        {
          label: "所属街道社区",
          prop: "communityAffiliation",
          formatter: (row, column, cellValue, index) => {
            if (row.communityAffiliation == 0 || row.communityAffiliation) {
              return '牛市口街道' + cascader[row.communityAffiliation].name
            }
          },
        },
        { label: "联系电话", prop: "phoneNum" },
        { label: "创建时间", prop: "createTime" },
        {
          label: "审核状态",
          prop: "checkStatus",
          formatter: (row, column, cellValue, index) => {
            if (row.checkStatus == 0) {
              return "已驳回";
            } else if (row.checkStatus == 1) {
              return "已通过";
            } else {
              return "未审核";
            }
          },
        },
      ],
      tableData: [],
      operation: {
        details: true,
        inte: true,
        inteNum: true,
        pass1: true,
        reject1: true,
        del: true,
        invite: true,
      },
      form: {
        integral: undefined,
        type: "",
        openId: "",
      },
      forms: {
        integral: undefined,
        type: "",
        openId: "",
      },
      dialogVisible: false,
      integralVisible: false,
      inviteVisible: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      options: [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
      ],
      num: "",
      preview: [],
      pageInvite: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      optionsType: [
        { id: 1, name: "减分" },
        { id: 0, name: "加分" },
      ],
      // company: [],
      inteNumVisible: false,
      InviteId: ''
    };
  },
  mounted() {
    // this.getCompany();
    this.getAllNum();
    this.getList();
  },
  methods: {
    async handleDownload() {
      console.log(this.search);
      const res = await this.$dow("/person-poi/getUserPoi", { ...this.search });
      console.log(res);
      // console.log(this.download);
      let url = window.URL.createObjectURL(
        new Blob([res], { type: "application/vnd.ms-excel;" })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "用户表");
      document.body.appendChild(link);
      link.click();
    },
    async getMyInvite(id) {
      const res = await this.$get('/person/getInvitationList', { openId: this.InviteId, ...this.pageInvite })
      if (res.flag) {
        // console.log(res);
        this.inviteData = res.data.data
        this.pageInvite.total = res.data.invitation
        this.inviteVisible = true;
      }
    },
    handelInviteChange(e) {
      console.log(e);
      this.pageInvite.pageNum = e;
      this.getMyInvite()
    },
    handleInput(val) {
      if (!this.search.endIntegral) {
        this.$message.warning("需要输入截止积分");
      }
    },
    handleInput1(val) {
      if (!this.search.startIntegral) {
        this.$message.warning("需要输入起始积分");
      }
    },
    async getList() {
      if (this.search.startIntegral || this.search.endIntegral) {
        this.search.startIntegral = this.search.startIntegral - 0;
        this.search.endIntegral = this.search.endIntegral - 0;
      }
      console.log(this.search);
      const res = await this.$get("/person/getList", {
        ...this.page,
        ...this.search,
      });
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    async getAllNum() {
      const res = await this.$get("/person/count");
      this.num = res.data;
    },
    getSearch() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getList();
    },
    // 公司
    // async getCompany() {
    //   const res = await this.$get("/company/getAll");
    //   this.company = res.data.rows;
    //   console.log(res);
    //   // if (res.data.flag) {
    //   //   this.setData({
    //   //     actionsCompany: res.data.data.rows
    //   //   })
    //   // }
    // },
    reset() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.search = {
        condition: "",
        companyName: "",
        gender: null,
        startIntegral: "",
        endIntegral: "",
      };
      this.getList();
    },
    handleEventButton(type, row) {
      if (type == "details") {
        this.form = row;
        this.preview.push(row.frontUrl);
        this.preview.push(row.backUrl);
        this.dialogVisible = true;
      } else if (type == "current") {
        this.page.pageNum = row;
        this.getList();
      } else if (type == "inte") {
        this.form.openId = row.openId;
        this.integralVisible = true;
      } else if (type == "pass1") {
        this.setAudit(row.openId, 1);
      } else if (type == "reject1") {
        this.setAudit(row.openId, 0);
      } else if (type == "del") {
        console.log(row);
        this.setDel(row.openId);
      } else if (type == "invite") {
        this.InviteId = row.openId
        this.getMyInvite(row.openId)

      } else {
        this.forms.openId = row.openId;
        this.inteNumVisible = true;
      }
    },
    setDel(id) {
      console.log(id);
      this.$confirm("确认删除？")
        .then(async (_) => {
          const res = await this.$del(`/person/delete/${id}`);
          console.log(res);
          if (res.flag) {
            this.$message.success(res.message);
            this.getList();
            this.getAllNum();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((_) => { });
    },
    async setAudit(id, check) {
      const res = await this.$put("/person/changeStatus", {
        openId: id,
        checkStatus: check,
      });
      console.log(res);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.console.error(res.message);
      }
    },
    async onIntergral(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$post("/person/changeIntegral", this.form);
          if (res) {
            this.integralVisible = false;
            this.form = {
              integral: undefined,
              type: "",
              openId: "",
            };
            this.getList();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onIntergralNum(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$post(
            "/person/changeCumulativeIntegral",
            this.forms
          );
          console.log(res);
          if (res.flag) {
            this.inteNumVisible = false;
            this.forms = {
              integral: undefined,
              type: "",
              openId: "",
            };
            this.getList();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 是否关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.form = {
            integral: undefined,
            type: "",
            openId: "",
          };
          this.integralVisible = false;
          done();
        })
        .catch((_) => { });
    },
    handleCloses() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.forms = {
            integral: undefined,
            type: "",
            openId: "",
          };
          this.inteNumVisible = false;
          done();
        })
        .catch((_) => { });
    },
    handleInviteCloses() {
      this.inviteVisible = false;
    },
  },

  computed: {
    rulesForm() {
      return {
        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
        type: [{ required: true, message: "请选择加减", trigger: "change" }],
      };
    },
    rulesForms() {
      return {
        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
        type: [{ required: true, message: "请选择加减", trigger: "change" }],
      };
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;

  .search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 16px;
    margin-top: 16px;

    .search_input {
      width: 80%;
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 0 0 5px;

      .btn {
        width: 110px;
        height: 40px;
      }
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.grid-content {
  border-radius: 4px;
}

.header_list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: Roboto, Roboto-Regular;
    color: rgba(51, 51, 51, 0.5);
  }

  h2 {
    display: inline-block;
    font-size: 20px;
    font-family: Roboto, Roboto-Bold;
    font-weight: 700;
    color: #2196f3;
  }

  .person {
    color: #ee5252;
    font-weight: 500;
  }

  .person1 {
    color: #36d375;
    font-weight: 500;
  }

  .person2 {
    color: #ff9d43;
  }
}

/deep/ .el-form-item__content {
  text-align: left;
}

.img {
  display: flex;
  justify-content: flex-start;
}

/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
